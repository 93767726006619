



























import { defineComponent, ref, useFetch } from "@nuxtjs/composition-api";
import CustomFormComp from "~/components/CustomForm.vue";
import { useContent, useCustomForm } from "~/composables";
import type { CmsPage, CustomForm } from "~/modules/GraphQL/types";

export default defineComponent({
	name: "ContactUsForm",
	components: {
		CustomFormComp,
	},
	props: {
		identifier: {
			type: String,
			default: "",
		},
	},
	setup() {
		const { loadPage, loading } = useContent();
		const { getCustomFormById } = useCustomForm();

		//// Contact Us Section ////
		const custom_form = ref<CustomForm | null>(null);
		const page = ref<CmsPage | null>(null);
		const { fetch: fetchContactUs } = useFetch(async () => {
			page.value = await loadPage({ identifier: "contact-us" });
			if (page.value.custom_form_id !== null) {
				custom_form.value = await getCustomFormById({
					formId: page.value.custom_form_id,
				});
			}
		});
		const contact_us_cover = ref({});
		contact_us_cover.value = {
			"background-image": `url(${require("@/assets/images/contact-us-cover.webp")})`,
			"background-position": "left center",
		};
		fetchContactUs();
		//// Contact Us Section ////

		// @ts-ignore
		return {
			loading,
			custom_form,
			contact_us_cover,
		};
	},
});
